import React, { useState, useEffect } from "react";
import {
	List,
	Space,
	Skeleton,
	Button,
	Drawer,
	message,
	Input,
	Checkbox,
	Transfer,
	Tooltip,
	Modal,
	Select,
	Row,
	Col
} from "antd";
import { ReloadOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { reload_nomenclature, edit_company, create_company } from "../../services/companies";
import "../../hover.css";
import PropTypes from "prop-types";

const no_selected_company = {
	id: "",
	address: "",
	country: "",
	description: "",
	is_associated_company: true,
	name: "",
	plan: "",
	accounting_resources: {
		accounting_plan: [],
		diaries: [],
		expense_accounts: [],
		sale_accounts: [],
		others:{
			analytic_accounts:[]
		}
	},
	database_id: "",
	tax_authority_specifications: {
		pequenio_contribuyente: false,
		tax_free: false
	},
	looker_link: "",
	database: null,
};

const { TextArea } = Input;

const no_new_company =  {
	tid: "",
	name: ""
};

const Companies = ({ token, companies, setCompanies }) => {
	const [loading, setLoading] = useState(true);
	const [loadingCreateCompany, setLoadingCreateCompany] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [nomenclatureLoading, setNomenclatureLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const placement = "right";
	const [pageSize, setPageSize] = useState(false);

	const [companiesTable, setCompaniesTable] = useState([]);
	const [selected, setSelected] = useState(no_selected_company);
	const [selectedKeysExpenses, setSelectedKeysExpenses] = useState([]);
	const [selectedKeysSales, setSelectedKeysSales] = useState([]);

	const [openCreate, setOpenCreate] = useState(false);

	const [hoveredItem, setHoveredItem] = useState(null);

	const [newCompany, setNewCompany] = useState(no_new_company);

	/* CREATE COMPANY */

	const showCreateCompanyModal = () => {
		setOpenCreate(true);
	};

	const cancelCreateCompany = () => {
		setOpenCreate(false);
	};

	const onChangeCompanyName = (value) => {
		setNewCompany({
			...newCompany,
			name: value
		});
	};

	const onChangeCompanyTid = (value) => {
		setNewCompany({
			...newCompany,
			tid: value
		});
	};

	const handleCreateAssociatedCompany = async () => {

		setLoadingCreateCompany(true);

		if (newCompany.name === "" || newCompany.tid === "") {
			message.error("Completa todos los valores.");
			setLoadingCreateCompany(false);
			return;
		}

		try {

			const res = await create_company(token, newCompany);

			if (res) {
				setLoadingCreateCompany(false);
				message.success(
					"Compañía Creada"
				);
				setOpenCreate(false);
				setNewCompany(no_new_company);
					
			}
		} catch (error) {
			setNewCompany(no_new_company);
			setLoadingCreateCompany(false);
			message.error("Ha ocurrido un error");
			setOpenCreate(false);
		}


	};

	/* COMPONENT VARIABLES */

	const showEdit = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
		setSelected(no_selected_company);
	};

	/* FORMATING */

	const reduceText = (text) => {
		if (text == "") {
			return "---";
		}
		if (text.length > 40) {
			return text.substring(0, 40) + "...";
		}
		return text;
	};

	/* SELECT INVOICE */

	const handleChangeNomenclatureExpenses = (newTargetKeys) => {
		const newSelected = {
			...selected,
			accounting_resources: {
				...selected.accounting_resources,
				expense_accounts: newTargetKeys
			}
		};
		setSelected(newSelected);
	};
	const handleChangeNomenclatureSales = (newTargetKeys) => {
		const newSelected = {
			...selected,
			accounting_resources: {
				...selected.accounting_resources,
				sale_accounts: newTargetKeys
			}
		};
		setSelected(newSelected);
	};

	const handleSelectChangeNomenclatureExpenses = (
		sourceSelectedKeys,
		targetSelectedKeys
	) => {
		setSelectedKeysExpenses([...sourceSelectedKeys, ...targetSelectedKeys]);
	};
	const handleSelectChangeNomenclatureSales = (
		sourceSelectedKeys,
		targetSelectedKeys
	) => {
		setSelectedKeysSales([...sourceSelectedKeys, ...targetSelectedKeys]);
	};

	const handleReloadNomenclature = async () => {
		setNomenclatureLoading(true);

		try {
			const resReload = await reload_nomenclature(token, selected.id);

			if (resReload) {
				message.success(
					"Recarga de nomenclatura en proceso, los cambios pueden tardar hasta 1 minuto."
				);
			}
		} catch (error) {
			message.error("Ha ocurrido un error");
		} finally {
			setNomenclatureLoading(false);
		}
	};

	/* SEARCH COMPANY */

	useEffect(() => {
		setCompaniesTable(companies);
		setLoading(false);

		const calculateListSize = () => {
			const windowHeight = window.innerHeight;
			const elementHeight = 65;
			const maxListHeight = windowHeight - 350;

			const numElements = Math.max(
				1,
				Math.floor(maxListHeight / elementHeight)
			);
			setPageSize(numElements);
		};

		window.addEventListener("resize", calculateListSize);
		calculateListSize();

		return () => window.removeEventListener("resize", calculateListSize);
	}, []);

	const onChangeSearch = (value) => {
		const text = value.target.value;

		const matchesText = (str) => {
			if (typeof str === "string") {
				return str.toLowerCase().includes(text.toLowerCase());
			}
			else if (typeof str === "number") {
				return String(str).toLowerCase().includes(text.toLowerCase());
			}
			return false;
		};

		const checkObjectForMatch = (obj) => {
			for (const key in obj) {
				if (matchesText(obj[key])) {
					return true;
				}

				if (typeof obj[key] === "object" && obj[key] !== null) {
					if (checkObjectForMatch(obj[key])) {
						return true;
					}
				}
			}
			return false;
		};

		if (text === "") {
			setCompaniesTable(companies);
		} else {
			const resultado = companies.filter((company) =>
				checkObjectForMatch(company)
			);
			setCompaniesTable(resultado);
		}
	};

	/* EDITING COMPANY PROPERTIES */

	const updateSelected = (path, value) => {
		const newSelected = { ...selected };

		const keys = path.split(".");
		let current = newSelected;

		for (let i = 0; i < keys.length - 1; i++) {
			current = current[keys[i]] = { ...current[keys[i]] };
		}

		current[keys[keys.length - 1]] = value;
		setSelected(newSelected);
	};

	// Usage:
	const handleChangeName = (value) => updateSelected("name", value);
	const handleChangeLookerLink = (value) =>
		updateSelected("looker_link", value);
	const handleChangeAddress = (value) => updateSelected("address", value);
	const handleChangeDescription = (value) =>
		updateSelected("description", value);
	const handleChangeCountry = (value) => updateSelected("country", value);
	const handleChangePC = (value) =>
		updateSelected(
			"tax_authority_specifications.pequenio_contribuyente",
			value
		);
	const handleChangeClient = (value) =>
		updateSelected("is_associated_company", value);
	
	const handleChangeTaxFree = (value) => updateSelected("tax_authority_specifications.tax_free", value);

	const handleChangePlan = (value) => updateSelected("plan", value);
	
	/* SUBMIT COMPANY */

	const update_table = (companies) => {
		const index = companies.findIndex(company => company.id === selected.id);
		if (index !== -1) {
			companies[index] = {...selected};
		}
		return companies;
	};

	const handleSubmit = async () => {
		setLoadingSubmit(true);
		setLoading(true);

		const data = {
			id: selected.id,
			address: selected.address,
			name: selected.name,
			country: selected.country,
			plan: selected.plan,
			description: selected.description,
			is_associated_company: selected.is_associated_company,
			tax_authority_specifications: selected.tax_authority_specifications,
			accounting_resources: selected.accounting_resources ? selected.accounting_resources : {
				accounting_plan: [],
				diaries: [],
				expense_accounts: [],
				sale_accounts: [],
				others:{
					analytic_accounts:[]
				}
			},
		};
		const res = await edit_company(token, data);
		if (res) {
			message.success(res);
			setCompaniesTable(update_table(companiesTable));
			setCompanies(update_table(companies));
			onClose();
		}
		
		setLoading(false);
		setLoadingSubmit(false);
		
	};

	return (
		<>
			<Skeleton avatar loading={loading} active>
				<Row>
					<Col
						span={12}
					>
						<Button type="primary" onClick={showCreateCompanyModal}>
							Crear Compañía Asociada
						</Button>
					</Col>
					<Col
						span={12}
					>
						<Input
							placeholder="Palabra Clave"
							style={{
								width:"90%",
							}}
							onChange={onChangeSearch}
							prefix={
								<SearchOutlined />
							}
						/>

					</Col>
				</Row>
				<List
					pagination={{
						position: "top",
						align: "end",
						disabled: false,
						showSizeChanger: false,
						pageSize: pageSize,
					}}
					style={{
						textAlign: "start",
					}}
					size="small"
					dataSource={companiesTable}
					renderItem={(item, index) => (
						<List.Item
							key={index}
							onMouseEnter={() => setHoveredItem(index)}
							onMouseLeave={() => setHoveredItem(null)}
							className={hoveredItem === index ? "hovered-item" : ""}
							onClick={() => {
								showEdit();
								setSelected(item);
							}}
							actions={[<a key="edit"></a>]}
						>
							<List.Item.Meta
								title={reduceText(item.name)}
								description={reduceText(item.address)}
							/>
							<div>{item.tid}</div>
						</List.Item>
					)}
				/>
			</Skeleton>
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />
			<Skeleton avatar active loading={loading} />

			<Drawer
				title="Editar Empresa"
				placement={placement}
				width={1000}
				onClose={onClose}
				open={open}
				extra={
					<Space>
						<Button
							onClick={() => {
								onClose();
								setSelected(no_selected_company);
							}}
						>
							Cancelar
						</Button>
						<Button
							type="primary"
							loading={loadingSubmit}
							onClick={handleSubmit}
						>
							Modificar
						</Button>
					</Space>
				}
			>
				<div>
					<h3>Nombre: </h3>
					<Input
						value={selected.name}
						onChange={(e) => handleChangeName(e.target.value)}
					/>
				</div>
				<div>
					<h3>Nit: </h3>
					<p>{selected.tid}</p>
				</div>
				<div>
					<h3>Dirección: </h3>
					<TextArea
						rows={4}
						showCount
						maxLength={150}
						value={selected.address}
						onChange={(e) => handleChangeAddress(e.target.value)}
					/>
				</div>
				<div>
					<h3>Descripción: </h3>
					<TextArea
						rows={5}
						showCount
						maxLength={300}
						value={selected.description}
						onChange={(e) => handleChangeDescription(e.target.value)}
					/>
				</div>
				<div>
					<h3>País: </h3>
					<Input
						showCount
						maxLength={5}
						value={selected.country}
						onChange={(e) => handleChangeCountry(e.target.value)}
					/>
				</div>
				<div>
					<h3>Pequeño Contribuyente: </h3>
					<Checkbox
						checked={
							selected.tax_authority_specifications.pequenio_contribuyente
						}
						onChange={(e) => handleChangePC(e.target.checked)}
					/>
				</div>
				<div>
					<h3>Exento de IVA</h3>
					<Checkbox
						checked={
							selected.tax_authority_specifications?.tax_free
						}
						onChange={(e) => handleChangeTaxFree(e.target.checked)}
					/>
				</div>
				<div>
					<h3>Cliente: </h3>
					<Checkbox
						checked={selected.is_associated_company}
						onChange={(e) => handleChangeClient(e.target.checked)}
					/>
				</div>
				<div>
					<h3>Plan: </h3>
					<Select
						style={
							{
								width:200
							}
						}
						showSearch
						placeholder="Selecciona un plan"
						value={selected?.plan}
						onChange={handleChangePlan}
						options={[
							{
								value: "advanced",
								label: "Avanzado",
							},
							{
								value: "simple",
								label: "Simple",
							},
							{
								value: "none",
								label: "Ninguno",
							},
						]}
					/>
				</div>
				{(selected.database === null) && <h3>No hay una base de datos en esta compañia</h3>}
				{selected.is_associated_company && (
					(selected.database !== null) &&
					<>
						<div>
							<h3>Nombre del Sistema de la Base de Datos: </h3>
							<p>{selected.database.system}</p>
						</div>
						<div>
							<h3>Nombre de la Base de Datos: </h3>
							<p>{selected.database.name}</p>
						</div>
						<div>
							<h3>Version: </h3>
							<p>{selected.database.version}</p>
						</div>
					</>
				)}
				{selected.is_associated_company && selected.database !== null && (
					<div>
						<h3>Administrador de Nomenclatura de Compras: </h3>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
							}}
						>
							<Tooltip title="Recargar">
								<Button
									loading={nomenclatureLoading}
									type="primary"
									shape="circle"
									icon={<ReloadOutlined />}
									onClick={() => {
										handleReloadNomenclature();
									}}
								/>
							</Tooltip>
						</div>
						<Transfer
							dataSource={
								selected.accounting_resources?.accounting_plan
									? selected.accounting_resources?.accounting_plan.map(
										(element) => ({
											...element,
											key: element.id,
										})
									)
									: []
							}
							showSearch
							oneWay
							listStyle={{
								width: 450,
								height: 450,
							}}
							titles={["Cuentas Disponibles", "Cuentas de Compra Asignadas"]}
							targetKeys={
								selected.accounting_resources?.expense_accounts
									? selected.accounting_resources?.expense_accounts
									: []
							}
							selectedKeys={selectedKeysExpenses}
							onChange={handleChangeNomenclatureExpenses}
							onSelectChange={handleSelectChangeNomenclatureExpenses}
							render={(item) => item.display_name}
							style={{
								marginBottom: 16,
								marginTop: "10px",
							}}
						/>
					</div>
				)}
				{selected.is_associated_company && selected.database !== null && (
					<div>
						<h3>Administrador de Nomenclatura de Ventas: </h3>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
							}}
						>
							<Tooltip title="Recargar">
								<Button
									loading={nomenclatureLoading}
									type="primary"
									shape="circle"
									icon={<ReloadOutlined />}
									onClick={() => {
										handleReloadNomenclature();
									}}
								/>
							</Tooltip>
						</div>
						<Transfer
							dataSource={
								selected.accounting_resources?.accounting_plan
									? selected.accounting_resources?.accounting_plan.map(
										(element) => ({
											...element,
											key: element.id,
										})
									)
									: []
							}
							showSearch
							oneWay
							listStyle={{
								width: 450,
								height: 450,
							}}
							titles={["Cuentas Disponibles", "Cuentas de Venta Asignadas"]}
							targetKeys={
								selected.accounting_resources?.sale_accounts
									? selected.accounting_resources?.sale_accounts
									: []
							}
							selectedKeys={selectedKeysSales}
							onChange={handleChangeNomenclatureSales}
							onSelectChange={handleSelectChangeNomenclatureSales}
							render={(item) => item.display_name}
							style={{
								marginBottom: 16,
								marginTop: "10px",
							}}
						/>
					</div>
				)}
				{selected.client && (
					<div>
						<h3>Link Reporte Looker Studio: </h3>
						<Input
							value={selected.looker_link}
							onChange={(e) => handleChangeLookerLink(e.target.value)}
						/>
					</div>
				)}
			</Drawer>
			<Modal 
				title="Crear Compañía Asociada" 
				open={openCreate} 
				onCancel={cancelCreateCompany}
				footer={
					<Button
						loading={loadingCreateCompany}
						type="primary"
						onClick={handleCreateAssociatedCompany}
					>
						Crear
					</Button>
				}
			>
				{
					!loadingCreateCompany &&
					<>
						<h3>Nombre</h3>
						<Input 
							placeholder="Nombre" 
							value={newCompany.name}
							onChange={(e) => onChangeCompanyName(e.target.value)} />
						<h3>NIT</h3>
						<Input 
							placeholder="Nit" 
							value={newCompany.tid}
							onChange={(e) => onChangeCompanyTid(e.target.value)} />
					</>
				}
				{
					loadingCreateCompany &&
					<LoadingOutlined />
				}
			</Modal>
		</>
	);
};

Companies.propTypes = {
	token: PropTypes.string.isRequired,
	companies: PropTypes.array.isRequired,
	setCompanies: PropTypes.func.isRequired,
};

export default Companies;
