import React, { useState, useRef, useEffect } from "react";

import {
	PlusOutlined,
} from "@ant-design/icons";

import {
	Space,
	Input,
	Tag,
	Tooltip
} from "antd";

import PropTypes from "prop-types";

const RecurrencyTags = ({tags, setTags}) => {
	const [inputValue, setInputValue] = useState("");
	const [editInputIndex, setEditInputIndex] = useState(-1);
	const [inputVisible, setInputVisible] = useState(false);	
	const [editInputValue, setEditInputValue] = useState("");
	const inputRef = useRef(null);
	const editInputRef = useRef(null);

	const handleClose = (removedTag) => {
		const newTags = tags.filter((tag) => tag !== removedTag);
		setTags(newTags);
	};

	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		if (inputValue && !tags.includes(inputValue)) {
			setTags([...tags, inputValue]);
		}
		setInputVisible(false);
		setInputValue("");
	};

	const handleEditInputChange = (e) => {
		setEditInputValue(e.target.value);
	};

	const handleEditInputConfirm = () => {
		const newTags = [...tags];
		newTags[editInputIndex] = editInputValue;
		setTags(newTags);
		setEditInputIndex(-1);
		setEditInputValue("");
	};

	const tagInputStyle = {
		width: 150,
		height: 22,
		marginInlineEnd: 8,
		verticalAlign: "top",
	};

	const tagPlusStyle = {
		height: 22,
		background: "white",
		borderStyle: "dashed",
	};

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	useEffect(() => {
		editInputRef.current?.focus();
	}, [editInputValue]);	

	return (<>
		<Space size={[0, 8]} wrap>
			{tags.map((tag, index) => {
				if (editInputIndex === index) {
					return (
						<Input
							ref={editInputRef}
							key={tag}
							size="small"
							style={tagInputStyle}
							value={editInputValue}
							onChange={handleEditInputChange}
							onBlur={handleEditInputConfirm}
							onPressEnter={handleEditInputConfirm}
						/>
					);
				}
				const isLongTag = tag.length > 20;
				const tagElem = (
					<Tag
						key={tag}
						closable={true}
						style={{
							userSelect: "none",
						}}
						onClose={() => handleClose(tag)}
					>
						<span
							onDoubleClick={(e) => {
								if (index !== 0) {
									setEditInputIndex(index);
									setEditInputValue(tag);
									e.preventDefault();
								}
							}}
						>
							{isLongTag ? `${tag.slice(0, 20)}...` : tag}
						</span>
					</Tag>
				);
				return isLongTag ? (
					<Tooltip title={tag} key={tag}>
						{tagElem}
					</Tooltip>
				) : (
					tagElem
				);
			})}
			{inputVisible ? (
				<Input
					ref={inputRef}
					type="text"
					size="small"
					maxLength={20}
					showCount
					style={tagInputStyle}
					value={inputValue}
					onChange={handleInputChange}
					onBlur={handleInputConfirm}
					onPressEnter={handleInputConfirm}
				/>
			) : (
				<Tag 
					style={tagPlusStyle} 
					icon={<PlusOutlined />} 
					onClick={showInput}>
				New Tag
				</Tag>
			)}
		</Space>
	</>);
};

RecurrencyTags.propTypes = {
	tags: PropTypes.array.isRequired,
	setTags: PropTypes.func.isRequired,
};

export default RecurrencyTags;