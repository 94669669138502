import axios from "axios";
import {  message } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_companies = async (token, type_search) => {
	let body = {
		token,
		type_search
	};
	try {
		const res = await axios.post(`${apiUrl}/get_companies`, body);
		if (res.status == 200) return res.data;
		else message.error(res.data);
		return [];
	} catch (err) {
		message.error(err.response.data);
	}
};

export const reload_nomenclature = async (token, company_id) => {
	// eslint-disable-next-line no-useless-catch
	try {
		const body = {
			token,
			company_id
		};
		const res = await axios.post(`${apiUrl}/get_nomenclature_notificator`, body);
		return res;
	} catch (err) {
		message.error(err.response.data);
	}
};

export const edit_company = async (token, data) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_company`, {
			token,
			edit_value: "edit",
			data
		});
		if (res.status == 200) return res.data;
		else message.error(res.data);
	} catch (err) {
		message.error(err.response.data);
	}
};

export const create_company = async (token, data) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_company`, {
			token,
			edit_value: "create",
			data
		});
		return res;
	} catch (err) {
		message.error(err.response.data);
	}
};
