import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const train_model = async (token, company_id, position) => {
    try {
        const res = await axios.post(
            `${apiUrl}/ml_model_generator`,
            { token, company_id, position },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return res;
    } catch (err) {
        console.error(err); // Log the error for debugging
        return err.response ? err.response.data : err.message;
    }
};


export const invoice_preprocessing = async (token, company_id, position) => {
    try {
        const res = await axios.post(
            `${apiUrl}/invoice_preprocessing_notificator`,
            { token, company_id, position },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return res;
    } catch (err) {
        console.error(err); // Log the error for debugging
        return err.response ? err.response.data : err.message;
    }
};

