import axios from "axios";
import { message } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_databases = async (token) => {
	try {
		const res = await axios.post(`${apiUrl}/get_databases`, {token});
		if (res.status == 200) return res.data;		
		message.error(res.data);
	} catch (err) {
		message.error(err.response.data);
	}
};

export const edit_database = async (token, edit_value, data) => {
	try{
		const body = { token, edit_value, data };
		const res = await axios.post(`${apiUrl}/edit_database`, body);
		if (res.status == 200) return res.data;
		message.error(res.data);
	} catch (err) {
		message.error(err.response.data);
	}
};