import axios from "axios";
import { message } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_errors = async (token, verified) => {
	try {
		const res = await axios.post(`${apiUrl}/get_errors`, {
			token,
			verified
		});
		if (res.status == 200) return res.data;
		else message.error(res.data);
		return [];
	} catch(err) {
		message.error(err.response.data);
	}
};

export const edit_errors = async (token, error_id) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_errors`, {
			token,
			error_id
		});
		if (res.status == 200) {
			if (res.data.length == 0) message.success("Edited Successfully");
			return res.data;
		}
		else message.error(res.data);
		return error_id.map(error => { return {id:error, message: false};});
	} catch (err) {
		message.error(err.response.data);
		return error_id.map(error => { return {id:error, message: false};});
	}
};