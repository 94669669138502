import React from "react";

import {
	Select,
} from "antd";

import PropTypes from "prop-types";

const AccountingClassificationSelect = ({accounting_classification_value, updateElement, company, position}) => {
	const accounting_classification = position == "in" ? company.accounting_resources?.sale_accounts : company.accounting_resources?.expense_accounts;
	return <Select
		showSearch
		placeholder="Clasificación"
		filterOption={(input, option) =>
			(option?.label ?? "")
				.toLowerCase()
				.includes(input.toLowerCase())
		}
		value={
			accounting_classification_value
		}
		style={{
			minWidth: "100px",
			width: "100%",
		}}
		popupMatchSelectWidth={false}
		options={
			company.accounting_resources?.accounting_plan && accounting_classification
				? company.accounting_resources?.accounting_plan
					.filter((element) =>
						accounting_classification.includes(
							element.id
						)
					)
					.map((element) => ({
						label: element.display_name,
						value: element.id,
					}))
				: []
		}
		onChange={(element) => updateElement(element)}
	/>;
};

const AnalyticClassificationSelect = ({analytic_classification_value, updateElement, company}) => {
	return <Select
		showSearch
		placeholder="Analíticas"
		filterOption={(input, option) =>
			(option?.label ?? "")
				.toLowerCase()
				.includes(input.toLowerCase())
		}
		status=""
		value={
			analytic_classification_value
		}
		style={{
			minWidth: "100px",
			width: "100%",
		}}
		popupMatchSelectWidth={false}
		options={
			company.accounting_resources?.others?.analytic_accounts
				? company.accounting_resources?.others?.analytic_accounts
					.map((element) => ({
						label: element.name,
						value: element.id,
					}))
				: []
		}
		onChange={(element) => updateElement(element)}
	/>;
};

AccountingClassificationSelect.propTypes = {
	accounting_classification_value: PropTypes.string.isRequired,
	updateElement: PropTypes.func.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string,
		tid: PropTypes.string,
		accounting_resources: PropTypes.shape({
			accounting_plan: PropTypes.any,
			expense_accounts: PropTypes.any,
			sale_accounts: PropTypes.any,
			others: PropTypes.shape({
				analytic_accounts: PropTypes.any
			})
		})
	}),
	position: PropTypes.string.isRequired,
};

AnalyticClassificationSelect.propTypes = {
	analytic_classification_value: PropTypes.string.isRequired,
	updateElement: PropTypes.func.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string,
		tid: PropTypes.string,
		accounting_resources: PropTypes.shape({
			accounting_plan: PropTypes.any,
			expense_accounts: PropTypes.any,
			sale_accounts: PropTypes.any,
			others: PropTypes.shape({
				analytic_accounts: PropTypes.any
			})
		})
	}),
};

export {AccountingClassificationSelect, AnalyticClassificationSelect};