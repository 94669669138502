import axios from "axios";
import {  message } from "antd";
import { array } from "prop-types";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_users = async (token) => {
	try {
		const res = await axios.post(`${apiUrl}/get_users`, {
			token
		});
		if (res.status == 200) return res.data;
		else message.error(res.data);
		return [];
	} catch (err) {
		message.error(err.response.data);
		return [];
	}
};

export const edit_user = async (data) => {
	try {
		const res = await axios.post(`${apiUrl}/edit_user`, data);
		if (res.status == 200) {
			message.success("Edited Correctly");
			return true;
		}
		else {
			console.log(res);
			if (res.data instanceof array) {
				for (const error of res.data) {
					message.error(error);
				}
			}
		}
		return false;
	} catch (err) {
		if (err.response.data instanceof Array) {
			for (const error of err.response.data) {
				message.info(error);
			}
		}
		return false;
	}
};
